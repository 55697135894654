import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useState } from 'react'

import { Incentive } from '../FarmDetails/details'

const SUPPORTED_CHAIN_IDS = [ChainId.GOAT_TESTNET]
const GRAPHQL_MAPPING: Record<number, string> = {
  250: process.env.REACT_APP_V3_GRAPH_URL_FARMS_FTM as string,
  199: process.env.REACT_APP_V3_GRAPH_URL_FARMS_BTTC as string,
  48816: process.env.REACT_APP_V3_GRAPH_URL_FARMS_GOAT_TESTNET as string,
}

const FARM_START_TIMESTAMPS: Record<number, number> = {
  250: process.env.REACT_APP_FARM_START_TIMESTAMP_V3_FTM
    ? parseInt(process.env.REACT_APP_FARM_START_TIMESTAMP_V3_FTM, 10)
    : 0, // Fantom
  199: process.env.REACT_APP_FARM_START_TIMESTAMP_V3_BTTC
    ? parseInt(process.env.REACT_APP_FARM_START_TIMESTAMP_V3_BTTC, 10)
    : 0, // BTTC
  48816: process.env.REACT_APP_FARM_START_TIMESTAMP_V3_GOAT_TESTNET
    ? parseInt(process.env.REACT_APP_FARM_START_TIMESTAMP_V3_GOAT_TESTNET, 10)
    : 0, // BTTC
  // Add more chains as needed
  // chainId: timestamp
}

// Helper function to get timestamp for a chain
const getFarmStartTimestamp = (chainId: number | undefined): number => {
  if (!chainId) return 0
  return FARM_START_TIMESTAMPS[chainId] || 0
}

// Add this constant at the top of the file
const OFFICIAL_FARM_CREATOR = process.env.REACT_APP_OFFICIAL_FARM_CREATOR_ADDRESS
  ? process.env.REACT_APP_OFFICIAL_FARM_CREATOR_ADDRESS
  : '0x4a14507784fecB4bbeADF5e8d34dC5Cf5b7f22a7'

// eslint-disable-next-line import/no-unused-modules
export type UserPosition = {
  id: string
  tokenId: string
  owner: string
  staked: boolean
}

type GraphQLResponse<T> = {
  data: T
  errors?: { message: string }[]
}

export const useV3UserPositions = (owner: string | undefined) => {
  const { chainId } = useWeb3React()
  const [positions, setPositions] = useState<UserPosition[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = useCallback(async () => {
    if (!owner || !chainId || !SUPPORTED_CHAIN_IDS.includes(chainId)) return
    const url = GRAPHQL_MAPPING[chainId]
    const query = `
      {
        positions(
          first: 1000
          where: {owner: "${owner}"}
        ) {
          id
          tokenId
          owner
          staked
        }
      }
    `

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    }

    try {
      const response = await fetch(url, options)
      if (!response.ok) throw new Error('Network response was not ok')

      const result: GraphQLResponse<{ positions: UserPosition[] }> = await response.json()
      if (result.errors) throw new Error(result.errors.map((e) => e.message).join(', '))

      setPositions(result.data.positions)
    } catch (error) {
      setError(error as Error)
    } finally {
      setLoading(false)
    }
  }, [owner, chainId])

  useEffect(() => {
    fetchData()
  }, [fetchData, owner, chainId])

  return { loading, positions, error, refetch: fetchData }
}

export const useV3FarmIncentives = (poolId: string | undefined) => {
  const { chainId } = useWeb3React()
  const [incentives, setIncentives] = useState<Incentive[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      if (!poolId || !chainId || !SUPPORTED_CHAIN_IDS.includes(chainId)) return

      const startTimestamp = getFarmStartTimestamp(chainId)
      const query = `
        {
          incentives(
            where: {id: "${poolId}", startTime_gte: ${startTimestamp}}
          ) {
            id
            pool
            rewardToken
            reward
            startTime
            endTime
            refundee
          }
        }
      `

      const url = GRAPHQL_MAPPING[chainId]
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
      }

      try {
        const response = await fetch(url, options)
        if (!response.ok) throw new Error('Network response was not ok')

        const result: GraphQLResponse<{ incentives: Incentive[] }> = await response.json()
        if (result.errors) throw new Error(result.errors.map((e) => e.message).join(', '))

        setIncentives(result.data.incentives)
      } catch (error) {
        setError(error as Error)
      } finally {
        setLoading(false)
        setError(null)
      }
    }

    fetchData()
  }, [chainId, poolId])

  return { loading, incentives, error }
}

// Update the useV3FarmIncentivesAll hook signature
export const useV3FarmIncentivesAll = (farmType: 'official' | 'community' = 'official', showCommunityFarms = false) => {
  const { chainId } = useWeb3React()
  const [incentives, setIncentives] = useState<Incentive[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      if (!chainId || !SUPPORTED_CHAIN_IDS.includes(chainId)) return

      const startTimestamp = getFarmStartTimestamp(chainId)
      const whereClause =
        farmType === 'official' ? `refundee: "${OFFICIAL_FARM_CREATOR}"` : `refundee_not: "${OFFICIAL_FARM_CREATOR}"`

      const query = `
        {
          incentives(
            first: 1000
            where: { ${whereClause}, startTime_gte: ${startTimestamp} }
          ) {
            id
            pool
            rewardToken
            reward
            startTime
            endTime
            refundee
          }
        }
      `

      const url = GRAPHQL_MAPPING[chainId]
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
      }

      try {
        const response = await fetch(url, options)
        if (!response.ok) throw new Error('Network response was not ok')

        const result: GraphQLResponse<{ incentives: Incentive[] }> = await response.json()
        if (result.errors) throw new Error(result.errors.map((e) => e.message).join(', '))

        const processedIncentives = result.data.incentives.map((incentive) => ({
          ...incentive,
          isOfficial: incentive.refundee.toLowerCase() === OFFICIAL_FARM_CREATOR.toLowerCase(),
        }))

        setIncentives(processedIncentives)
      } catch (error) {
        setError(error as Error)
      } finally {
        setLoading(false)
        setError(null)
      }
    }

    fetchData()
  }, [chainId, farmType, showCommunityFarms])

  return { loading, incentives, error }
}
