/* eslint-disable react-hooks/exhaustive-deps */
import { Trans } from '@lingui/macro'
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfacePageName,
  InterfaceSectionName,
  SharedEventName,
  SwapEventName,
} from '@uniswap/analytics-events'
import { ChainId, Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { UNIVERSAL_ROUTER_ADDRESS } from '@uniswap/universal-router-sdk'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent, Trace, TraceEvent, useTrace } from 'analytics'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { showMobileConsoleAtom } from 'components/AccountDrawer/MobileConsoleToggle'
import AddressInputPanel from 'components/AddressInputPanel'
import { ButtonError, ButtonPrimary } from 'components/Button'
import { GrayCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import Img from 'components/Img'
import MobileConsole from 'components/MobileConsole/MobileConsole'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import { QuoteManager, Quoters } from 'components/Quoter/QuoteManager'
import { AutoRow } from 'components/Row'
import AdminConfigurationPanel from 'components/swap/adminConfigurationPanel'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import { LiquidityHub } from 'components/swap/LiquidityHub'
import PriceImpactModal from 'components/swap/PriceImpactModal'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import { ArrowWrapper, PageWrapper, SwapWrapper } from 'components/swap/styled'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import SwapHeader from 'components/swap/SwapHeader'
import TradePrice from 'components/swap/TradePrice'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal'
import { PARASWAP_V5_PROXY_ROUTER_ADDRESS } from 'constants/addresses'
import { getChainInfo } from 'constants/chainInfo'
import { asSupportedChain, BestSwapNewChains, isSupportedChain } from 'constants/chains'
import { BIG_INT_ZERO, PARASWAP_SUPPORTED_CHAINS, ZERO_PERCENT } from 'constants/misc'
import { getSwapCurrencyId, TOKEN_SHORTHANDS } from 'constants/tokens'
import { useFotAdjustmentsEnabled } from 'featureFlags/flags/fotAdjustments'
import { useCurrency, useDefaultActiveTokens } from 'hooks/Tokens'
import { useIsSwapUnsupported } from 'hooks/useIsSwapUnsupported'
import { useRateMaxAmountIn } from 'hooks/useMaxAmountIn'
import usePermit2Allowance, { AllowanceState, useContractAllowance } from 'hooks/usePermit2Allowance'
import usePrevious from 'hooks/usePrevious'
import { SwapResult, useSwapCallback } from 'hooks/useSwapCallback'
import { useSwapTaxes } from 'hooks/useSwapTaxes'
import { useSwitchChain } from 'hooks/useSwitchChain'
import useWrapCallback, { WrapErrorText, WrapType } from 'hooks/useWrapCallback'
import { useAtomValue } from 'jotai/utils'
import { ReactNode, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { useAppSelector } from 'state/hooks'
import { IHasQuoteProperties, IHasTradeProperties, TradeFillType, TradeState } from 'state/routing/types'
import { Field, forceExactInput, replaceSwapState } from 'state/swap/actions'
import { useBaseSwapInfo, useDefaultsFromURLSearch, useSwapActionHandlers } from 'state/swap/hooks'
import swapReducer, { initialState as initialSwapState, SwapState } from 'state/swap/reducer'
import styled, { useTheme } from 'styled-components'
import { CloseIcon, LinkStyledButton, ThemedText } from 'theme'
import { srcAce, srcGrim } from 'theme/assets'
import { maybeLogFirstSwapAction } from 'tracing/swapFlowLoggers'
import { getChainFromUrl } from 'utils/dynamicSwapRoute'
import { formatCurrencyAmount, NumberType } from 'utils/formatNumbers'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { warningSeverity } from 'utils/prices'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'
import { isMobile } from 'utils/userAgent'

import { useIsDarkMode } from '../../theme/components/ThemeToggle'
import { OutputTaxTooltipBody } from './TaxTooltipBody'
const ArrowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const SwapContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.accent1};
  border-radius: 20px;
  padding: 24px;
`

const SwapSection = styled.div`
  background-color: ${({ theme }) => theme.surface2};
  border-radius: 16px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 14px;
  font-weight: 500;
  height: 120px;
  line-height: 20px;
  margin: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 16px;
  position: relative;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    border: 1px solid ${({ theme }) => theme.surface2};
  }

  &:hover:before {
    border-color: ${({ theme }) => theme.deprecated_stateOverlayHover};
  }

  &:focus-within:before {
    border-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
  }
` // Style the popup
const FiatPopupContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #162545;
  color: white;
  padding: 18px 10px; /* Increased padding */
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    right: 0px;
    bottom: 40px;
  }
`

const FiatPopupContent = styled.div`
  display: flex;
  align-items: flex-start; // Align items to the top
  flex-direction: row; // Stack content vertically
  max-width: 80%;
`

const FiatPopupIcon = styled.div`
  margin-right: 16px; /* Increased margin */
  width: 120px;

  img {
    width: 100%;
    height: auto;
  }
`

const PopupText = styled.span`
  font-size: 16px; /* Increased font size */
  font-weight: 500;
`

const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888; /* Light gray text */
`

const MeldIconContainer = styled.div`
  width: 32px;
  margin-left: 4px;

  img {
    width: 100%;
    height: auto;
  }
`

const BuyWithFiatLink = styled.span`
  font-size: 14px;
  color: #4299e1; /* Light blue link color */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-left: 4px;
  }
`

const CatImageContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px; /* Increased image size */
  height: 200px;

  img {
    width: 100%;
    height: auto;
    opacity: 0.2;
  }
`
const ButtonSwapContainer = styled.div`
  margin: 24px;
`

const CatContainer = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: ${({ gap }) => gap ?? 0};

  ${({ theme }) => theme.breakpoint.md} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`
const MeldIframeContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1021; // Ensure iframe is above other elements
`

const MeldIframeCloseButton = styled(CloseIcon)`
  position: absolute;
  top: 25px;
  right: 10px;
  color: white;
  z-index: 10000; // Ensure button is above iframe
  cursor: pointer;
`

const OutputSwapSection = styled(SwapSection)`
  border-bottom: ${({ theme }) => `1px solid ${theme.surface1}`};
`

const ImageWrapper = styled.div`
  margin-top: 100px;
  @media only screen and (max-width: 945px) {
    display: none;
  }
`

function getIsValidSwapQuote(
  wrappedTrade: IHasTradeProperties | undefined,
  swapInputError?: ReactNode,
  currencyBalance?: CurrencyAmount<Currency>
): boolean {
  return Boolean(!swapInputError && wrappedTrade)
}

// eslint-disable-next-line import/no-unused-modules
export default function BestSwapPage({ className }: { className?: string }) {
  const { chainId: connectedChainId } = useWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()

  const [showFiatPopup, setShowFiatPopup] = useState(false)
  const [showMeldIframe, setShowMeldIframe] = useState(false)

  const showMobileConsole = useAtomValue(showMobileConsoleAtom)

  const handleFiatPopupClick = () => {
    setShowMeldIframe(true)
  }
  const meldIcon = 'https://quickswap.exchange/static/media/meldIcon.d28d7bf528e28110c87a.webp'
  const location = useLocation()
  const offChain = getChainFromUrl(location)

  // const supportedChainId = asSupportedChain(connectedChainId)
  // Currently we only support fantom for best swap
  const supportedChainId = [
    ChainId.FANTOM,
    ChainId.EON,
    ChainId.BIT_TORRENT_MAINNET,
    ChainId.BERA_TESTNET,
    ChainId.SONIC_TESTNET,
    ChainId.GOAT_TESTNET,
    ...BestSwapNewChains,
  ].includes(connectedChainId as ChainId)
    ? connectedChainId
    : ChainId.FANTOM

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <CatContainer gap="12px">
        <ImageWrapper>{!isMobile && <Img alt="mirrorGrim" src={srcGrim} width={280} />}</ImageWrapper>

        <PageWrapper>
          <BestSwapInnerContent
            className={className}
            chainId={supportedChainId ?? offChain}
            prefilledState={{
              [Field.INPUT]: { currencyId: loadedUrlParams?.[Field.INPUT]?.currencyId },
              [Field.OUTPUT]:
                connectedChainId === 250
                  ? { currencyId: loadedUrlParams?.[Field.OUTPUT]?.currencyId }
                  : { currencyId: null },
            }}
            disableTokenInputs={supportedChainId !== undefined && supportedChainId !== connectedChainId}
          />
          {showMobileConsole && <MobileConsole />}
          <AdminConfigurationPanel></AdminConfigurationPanel>
          <NetworkAlert />
        </PageWrapper>
        <ImageWrapper>{!isMobile && <Img alt="mirrorAce" src={srcAce} width={280} />}</ImageWrapper>
      </CatContainer>
      {location.pathname === '/swap' && <SwitchLocaleLink />}
      {showMeldIframe && (
        <MeldIframeContainer onClick={() => setShowMeldIframe(false)}>
          <MeldIframeCloseButton size={18} fontWeight={700} onClick={() => setShowMeldIframe(false)} />
          <iframe
            src="https://meldcrypto.com/?publicKey=WXDsAnXZ8t7Nu9QB5kPJS5:7sjEWU1mp48FTCYJEnro6T1fwVHEU&destinationCurrencyCode=FTM"
            width="470px"
            height="95%"
            style={{ borderRadius: '8px' }}
          />
        </MeldIframeContainer>
      )}
      {showFiatPopup && (
        <FiatPopupContainer>
          <FiatPopupContent onClick={handleFiatPopupClick}>
            <FiatPopupIcon>
              <img src="https://i.imgur.com/dmHOoD6.png" alt="Credit Card" />
            </FiatPopupIcon>
            <div>
              <PopupText>
                <Trans>Instantly Buy & Sell Crypto with Fiat!</Trans>
              </PopupText>
              <PoweredBy>
                Powered by
                <MeldIconContainer>
                  <img src={meldIcon} alt="Meld Icon" />
                </MeldIconContainer>
              </PoweredBy>

              <BuyWithFiatLink>Buy with Fiat</BuyWithFiatLink>
            </div>
          </FiatPopupContent>

          <CloseIcon
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              color: 'white',
              zIndex: 1090,
            }}
            size={18}
            fontWeight={700}
            z={1090}
            onClick={() => setShowFiatPopup(false)}
          />
          <CatImageContainer>
            <img src="https://assets.spooky.fi/mirror_grim.png" alt="Cat" />
          </CatImageContainer>
        </FiatPopupContainer>
      )}
    </Trace>
  )
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */
// eslint-disable-next-line import/no-unused-modules
function BestSwapContent({
  className,
  prefilledState = {},
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
}: {
  className?: string
  prefilledState?: Partial<SwapState>
  chainId?: ChainId
  onCurrencyChange?: (selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>) => void
  disableTokenInputs?: boolean
}) {
  const { account, chainId: connectedChainId, connector } = useWeb3React()

  const trace = useTrace()
  // token warning stuff
  const prefilledInputCurrency = useCurrency(prefilledState?.[Field.INPUT]?.currencyId)
  const prefilledOutputCurrency = useCurrency(prefilledState?.[Field.OUTPUT]?.currencyId)

  const [loadedInputCurrency, setLoadedInputCurrency] = useState(prefilledInputCurrency)
  const [loadedOutputCurrency, setLoadedOutputCurrency] = useState(prefilledOutputCurrency)

  useEffect(() => {
    setLoadedInputCurrency(prefilledInputCurrency)
    setLoadedOutputCurrency(prefilledOutputCurrency)
  }, [prefilledInputCurrency, prefilledOutputCurrency])

  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [showPriceImpactModal, setShowPriceImpactModal] = useState<boolean>(false)

  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useDefaultActiveTokens(chainId)
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = asSupportedChain(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  // swap state
  const [state, dispatch] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })
  const { typedValue, recipient, independentField } = state

  const previousConnectedChainId = usePrevious(connectedChainId)
  const previousPrefilledState = usePrevious(prefilledState)

  useEffect(() => {
    const combinedInitialState = { ...initialSwapState, ...prefilledState }
    const chainChanged = previousConnectedChainId && previousConnectedChainId !== connectedChainId
    const prefilledInputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.INPUT]?.currencyId !== prefilledState?.[Field.INPUT]?.currencyId
    const prefilledOutputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.OUTPUT]?.currencyId !== prefilledState?.[Field.OUTPUT]?.currencyId
    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      dispatch(
        replaceSwapState({
          ...initialSwapState,
          ...prefilledState,
          field: combinedInitialState.independentField ?? Field.INPUT,
          inputCurrencyId: combinedInitialState.INPUT.currencyId ?? undefined,
          outputCurrencyId: combinedInitialState.OUTPUT.currencyId ?? undefined,
        })
      )
      // reset local state
      setSwapState({
        tradeToConfirm: undefined,
        swapError: undefined,
        showConfirm: false,
        swapResult: undefined,
      })

      setBestQuote(undefined)
    }
  }, [connectedChainId, prefilledState, previousConnectedChainId, previousPrefilledState])

  const [bestQuote, setBestQuote] = useState<IHasQuoteProperties | undefined>(undefined)
  const swapInfo = useBaseSwapInfo(state, chainId)
  const {
    allowedSlippage,
    autoSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapRawInputError,
  } = swapInfo

  const swapInputError = useMemo(() => {
    let error = swapRawInputError
    if (!bestQuote) return error
    if (!currencyBalances[Field.INPUT] || currencyBalances[Field.INPUT].lessThan(bestQuote.inputAmount)) {
      error = swapRawInputError ?? <Trans>Insufficient Balance</Trans>
    }

    return error
  }, [swapRawInputError, currencyBalances[Field.INPUT], bestQuote])

  const inputCurrency = currencies[Field.INPUT] ?? undefined
  const outputCurrency = currencies[Field.OUTPUT] ?? undefined

  const fotAdjustmentsEnabled = useFotAdjustmentsEnabled()
  const { inputTax, outputTax } = useSwapTaxes(
    inputCurrency?.isToken && fotAdjustmentsEnabled ? inputCurrency.address : undefined,
    outputCurrency?.isToken && fotAdjustmentsEnabled ? outputCurrency.address : undefined
  )

  const [inputTokenHasTax, outputTokenHasTax] = useMemo(
    () => [!inputTax.equalTo(0), !outputTax.equalTo(0)],
    [inputTax, outputTax]
  )

  const liquidityHubSwap = useMemo(() => bestQuote?.dependencies?.liquidityHubSwap, [bestQuote])
  const liquidityHubInitAnalytics = useMemo(() => bestQuote?.dependencies?.liquidityHubInitAnalytics, [bestQuote])
  const tradeState = useMemo(() => bestQuote?.dependencies?.tradeState, [bestQuote])

  const onSelectQuote = useCallback((quote?: IHasQuoteProperties) => setBestQuote(quote), [setBestQuote])

  // const isLoading = loadingOptimalRate || liquidityHub.quoteLoading || isTradeRouteIsLoading

  const isLHSwap = bestQuote?.quoter === Quoters.LIQUIDITY_HUB

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]:
              independentField === Field.INPUT ? parsedAmount : bestQuote ? bestQuote.inputAmount : undefined,
            [Field.OUTPUT]:
              independentField === Field.OUTPUT ? parsedAmount : bestQuote ? bestQuote.outputAmount : undefined,
          },
    [showWrap, parsedAmount, independentField, bestQuote]
  )

  const isTokenInputMulti = currencies[Field.INPUT]?.symbol?.includes('_MULTI') || false
  const isTokenOutputMulti = currencies[Field.OUTPUT]?.symbol?.includes('_MULTI') || false
  const isSwapWithNoTokenMulti = !isTokenInputMulti && !isTokenOutputMulti

  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT]) && isSwapWithNoTokenMulti
  const showFiatValueOutput = Boolean(parsedAmounts[Field.OUTPUT]) && isSwapWithNoTokenMulti

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(() => {
    if (!bestQuote) {
      return [false, true, true]
    }
    // Syncing and quoting is now the same since we need the quote intially
    return bestQuote.quoter == Quoters.CLIENT
      ? [tradeState === TradeState.NO_ROUTE_FOUND, tradeState === TradeState.LOADING, tradeState === TradeState.LOADING]
      : // if we have a quote back then there is a route, and its done loading
        [false, false, false]
  }, [tradeState, bestQuote])

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    if (!bestQuote) {
      return { priceImpactSeverity: 0, largerPriceImpact: undefined }
    }

    // if we cannot calculate the price impact and there is an amount to receive for we will show the price impact warning
    if (!bestQuote.priceImpact && !bestQuote.outputAmount.equalTo(0)) {
      return { priceImpactSeverity: 4, largerPriceImpact: undefined }
    }

    return { priceImpactSeverity: warningSeverity(bestQuote.priceImpact), largerPriceImpact: bestQuote.priceImpact }
  }, [bestQuote])

  const wrappedTrade: IHasTradeProperties | undefined = useMemo(() => {
    if (!bestQuote) return

    const formattedTradeProperties: IHasTradeProperties = {
      ...bestQuote,
      // Token Tax is calculated and already is factored into the amount out,
      // we're passing the same value so the architecture can remain congruent with the trade interface
      totalTaxRate: ZERO_PERCENT,
      //TODO: We need to change the fill type but the result relies on classic,
      inputTax,
      outputTax,
      // TODO: Consider removing this as it was only used for uniswapX
      // wrapInfo: { needsWrap: false },
      //totalGasUseEstimateUSD?: number
    }

    return formattedTradeProperties
  }, [bestQuote, inputTax, outputTax])

  useEffect(() => {
    // Force exact input if the user switches to an output token with tax
    if (outputTokenHasTax && independentField === Field.OUTPUT) {
      dispatch(forceExactInput())
    }
  }, [independentField, outputTokenHasTax, bestQuote?.outputAmount])

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers(dispatch)
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
      maybeLogFirstSwapAction(trace)
      setBestQuote(undefined)
    },
    [onUserInput, trace]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
      maybeLogFirstSwapAction(trace)
      setBestQuote(undefined)
    },
    [onUserInput, trace]
  )

  const navigate = useNavigate()
  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm: rateToConfirm, swapError, swapResult }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm?: IHasTradeProperties
    swapError?: Error
    swapResult?: SwapResult
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    swapError: undefined,
    swapResult: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatCurrencyAmount(parsedAmounts[dependentField], NumberType.SwapTradeAmount, ''),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue, bestQuote]
  )

  function isParaswapSupported(chainId: ChainId) {
    return PARASWAP_SUPPORTED_CHAINS.includes(chainId)
  }

  const maximumAmountIn = useRateMaxAmountIn(bestQuote, allowedSlippage)
  const rateAllowance = useContractAllowance(
    chainId && isParaswapSupported(chainId) ? PARASWAP_V5_PROXY_ROUTER_ADDRESS[chainId] : undefined,
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>) : undefined)
  )

  const tradeAllowance = usePermit2Allowance(
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken
        ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>)
        : undefined),
    isSupportedChain(chainId) ? UNIVERSAL_ROUTER_ADDRESS(chainId) : undefined,
    bestQuote?.quoter === Quoters.CLIENT ? TradeFillType.Classic : undefined
  )

  const allowance = bestQuote?.quoter === Quoters.PARASWAP ? rateAllowance : tradeAllowance

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))
  const handleContinueToReviewDex = useCallback(() => {
    setSwapState({
      tradeToConfirm: wrappedTrade,
      swapError: undefined,
      showConfirm: true,
      swapResult: undefined,
    })
  }, [wrappedTrade])

  const handleOnWrap = useCallback(async () => {
    if (!onWrap) return
    try {
      const txHash = await onWrap()
      setSwapState((currentState) => ({
        ...currentState,
        swapError: undefined,
        txHash,
      }))
      onUserInput(Field.INPUT, '')
    } catch (error) {
      if (!didUserReject(error)) {
        sendAnalyticsEvent(SwapEventName.SWAP_ERROR, {
          wrapType,
          input: currencies[Field.INPUT],
          output: currencies[Field.OUTPUT],
        })
      }
      console.error('Could not wrap/unwrap', error)
      setSwapState((currentState) => ({
        ...currentState,
        swapError: error,
        txHash: undefined,
      }))
    }
  }, [currencies, onUserInput, onWrap, wrapType])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, showConfirm: false }))
    // If there was a swap, we want to clear the input
    if (swapResult) {
      onUserInput(Field.INPUT, '')
      setBestQuote(undefined)
    }
  }, [onUserInput, swapResult])

  const handleAcceptChanges = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, tradeToConfirm: wrappedTrade }))
  }, [wrappedTrade])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: {
          currencyId: getSwapCurrencyId(inputCurrency),
        },
        [Field.OUTPUT]: state[Field.OUTPUT],
      })
      setBestQuote(undefined)
      maybeLogFirstSwapAction(trace)
    },
    [onCurrencyChange, onCurrencySelection, state, trace]
  )
  const inputCurrencyNumericalInputRef = useRef<HTMLInputElement>(null)

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    maybeLogFirstSwapAction(trace)
    setBestQuote(undefined)
  }, [maxInputAmount, onUserInput, trace])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: state[Field.INPUT],
        [Field.OUTPUT]: {
          currencyId: getSwapCurrencyId(outputCurrency),
        },
      })
      maybeLogFirstSwapAction(trace)
      setBestQuote(undefined)
    },
    [onCurrencyChange, onCurrencySelection, state, trace]
  )
  const showPriceImpactWarning = priceImpactSeverity > 3 && isSwapWithNoTokenMulti

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(BIG_INT_ZERO)
  )

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (wrappedTrade || routeIsLoading || routeIsSyncing)
  )

  const swapFiatValues = useMemo(() => {
    if (!bestQuote) {
      return {
        amountIn: undefined,
        amountout: undefined,
      }
    }
    return {
      amountIn: bestQuote.fiatValueInput.data,
      amountOut: bestQuote.fiatValueOutput.data,
    }
  }, [bestQuote])

  const swapCallback = useSwapCallback(
    wrappedTrade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined,
    recipient
  )

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    // TODO: add this logic back in for clientside swap etc
    // if (preTaxStablecoinPriceImpact && !confirmPriceImpactWithoutFee(preTaxStablecoinPriceImpact)) {
    //   return
    // }

    setSwapState((currentState) => ({
      ...currentState,
      swapError: undefined,
      swapResult: undefined,
    }))
    swapCallback()
      .then((result) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: undefined,
          swapResult: result,
        }))
      })
      .catch((error) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: error,
          swapResult: undefined,
        }))
      })
  }, [swapCallback])

  const switchChain = useSwitchChain()
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)
  const isDark = useIsDarkMode()

  const handleContinueToReview = useCallback(() => {
    if (!isLHSwap) {
      handleContinueToReviewDex()
      return
    }

    if (!liquidityHubSwap) {
      console.log('Cannot Continue to Review liquidity hub does not exist')
      return
    }

    if (liquidityHubInitAnalytics) {
      liquidityHubInitAnalytics()
    }
    liquidityHubSwap()
  }, [liquidityHubSwap, isLHSwap, handleContinueToReviewDex])

  const isSwapButtonDisabled = useMemo(() => {
    if (swapInputError) return true
    if (isLHSwap && bestQuote?.outputAmount.equalTo(0)) return true
    const isValidSwapQuote = getIsValidSwapQuote(wrappedTrade, swapInputError, currencyBalances[Field.INPUT])
    if (!isValidSwapQuote) return true

    return false
  }, [wrappedTrade, swapInputError, isLHSwap])

  const quoteManagerParams = useMemo(() => {
    if (!parsedAmount || !inputCurrency || !outputCurrency) return undefined

    return {
      allowedSlippage,
      inputCurrency,
      outputCurrency,
      inputTax,
      outputTax,
      tradeType: independentField === Field.INPUT ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
      amount: parsedAmount.quotient,
    }
  }, [allowedSlippage, inputCurrency, outputCurrency, inputTax, outputTax, independentField, parsedAmount])

  const swapElement = (
    <SwapContainer>
      <SwapWrapper isDark={isDark} className={className} id="swap-page">
        <TokenSafetyModal
          isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
          tokenAddress={importTokensNotInDefault[0]?.address}
          secondTokenAddress={importTokensNotInDefault[1]?.address}
          onContinue={handleConfirmTokenWarning}
          onCancel={handleDismissTokenWarning}
          showCancel={true}
        />
        <SwapHeader isUniXTrade={false} autoSlippage={autoSlippage} chainId={chainId} />
        {wrappedTrade && bestQuote && showConfirm && allowance.state !== AllowanceState.LOADING && (
          <ConfirmSwapModal
            trade={wrappedTrade}
            originalTrade={rateToConfirm}
            inputCurrency={inputCurrency}
            onAcceptChanges={handleAcceptChanges}
            onCurrencySelection={onCurrencySelection}
            swapResult={swapResult}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            allowance={allowance}
            swapError={swapError}
            onDismiss={handleConfirmDismiss}
            fiatValueInput={bestQuote.fiatValueInput}
            fiatValueOutput={bestQuote.fiatValueOutput}
          />
        )}
        {showPriceImpactModal && showPriceImpactWarning && (
          <PriceImpactModal
            priceImpact={largerPriceImpact}
            onDismiss={() => setShowPriceImpactModal(false)}
            onContinue={() => {
              setShowPriceImpactModal(false)
              handleContinueToReview()
            }}
          />
        )}

        <div style={{ display: 'relative' }}>
          <SwapSection>
            <Trace section={InterfaceSectionName.CURRENCY_INPUT_PANEL}>
              <SwapCurrencyInputPanel
                label={<Trans>You pay</Trans>}
                disabled={disableTokenInputs}
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={showMaxButton}
                currency={currencies[Field.INPUT] ?? null}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                fiatValue={showFiatValueInput ? bestQuote?.fiatValueInput : undefined}
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                showCommonBases
                id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
                loading={independentField === Field.OUTPUT && routeIsSyncing}
                ref={inputCurrencyNumericalInputRef}
              />
            </Trace>
          </SwapSection>
          <ArrowWrapper clickable={isSupportedChain(chainId)}>
            <TraceEvent
              events={[BrowserEvent.onClick]}
              name={SwapEventName.SWAP_TOKENS_REVERSED}
              element={InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
            >
              <ArrowContainer
                data-testid="swap-currency-button"
                onClick={() => {
                  if (disableTokenInputs) return
                  onSwitchTokens(inputTokenHasTax, formattedAmounts[dependentField])
                  maybeLogFirstSwapAction(trace)
                  setBestQuote(undefined)
                }}
                color={theme.neutral1}
              >
                <ArrowDown size="16" color={theme.neutral1} />
              </ArrowContainer>
            </TraceEvent>
          </ArrowWrapper>
        </div>
        <AutoColumn gap="xs">
          <div>
            <OutputSwapSection>
              <Trace section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}>
                <SwapCurrencyInputPanel
                  value={isLHSwap ? bestQuote?.outputAmount.toSignificant() || '' : formattedAmounts[Field.OUTPUT]}
                  disabled={disableTokenInputs}
                  onUserInput={handleTypeOutput}
                  label={<Trans>You receive</Trans>}
                  showMaxButton={false}
                  hideBalance={false}
                  fiatValue={showFiatValueOutput ? bestQuote?.fiatValueOutput : undefined}
                  priceImpact={largerPriceImpact}
                  currency={currencies[Field.OUTPUT] ?? null}
                  onCurrencySelect={handleOutputSelect}
                  otherCurrency={currencies[Field.INPUT]}
                  showCommonBases
                  id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                  loading={independentField === Field.INPUT && routeIsSyncing}
                  numericalInputSettings={{
                    // We disable numerical input here if the selected token has tax, since we cannot guarantee exact_outputs for FOT tokens
                    disabled: outputTokenHasTax,
                    // Focus the input currency panel if the user tries to type into the disabled output currency panel
                    onDisabledClick: () => inputCurrencyNumericalInputRef.current?.focus(),
                    disabledTooltipBody: <OutputTaxTooltipBody currencySymbol={currencies[Field.OUTPUT]?.symbol} />,
                  }}
                />
              </Trace>
              {recipient !== null && !showWrap ? (
                <>
                  <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                    <ArrowWrapper clickable={false}>
                      <ArrowDown size="16" color={theme.neutral2} />
                    </ArrowWrapper>
                    <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                      <Trans>- Remove recipient</Trans>
                    </LinkStyledButton>
                  </AutoRow>
                  <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                </>
              ) : null}
            </OutputSwapSection>
          </div>
          {!!quoteManagerParams && wrapType === WrapType.NOT_APPLICABLE && (
            <QuoteManager
              swapParams={quoteManagerParams}
              onSelectQuote={onSelectQuote} // Add the callback to handle selected quote
            />
          )}
          {showDetailsDropdown && (
            <div>
              <BestSwapDetailsDropdownContainer
                priceImpact={largerPriceImpact}
                isLHSwap={isLHSwap}
                wrappedTrade={wrappedTrade}
                loading={!bestQuote}
                allowedSlippage={allowedSlippage}
              />
            </div>
          )}
          {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}

          <ButtonSwapContainer>
            {swapIsUnsupported ? (
              <ButtonPrimary $borderRadius="16px" disabled={true}>
                <ThemedText.DeprecatedMain mb="4px">
                  <Trans>Unsupported Asset</Trans>
                </ThemedText.DeprecatedMain>
              </ButtonPrimary>
            ) : switchingChain ? (
              <ButtonPrimary $borderRadius="16px" disabled={true}>
                <Trans>Connecting to {getChainInfo(switchingChain)?.label}</Trans>
              </ButtonPrimary>
            ) : !account ? (
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                properties={{
                  received_swap_quote: getIsValidSwapQuote(wrappedTrade, swapInputError, currencyBalances[Field.INPUT]),
                }}
                element={InterfaceElementName.CONNECT_WALLET_BUTTON}
              >
                <ButtonPrimary onClick={toggleWalletDrawer} fontWeight={535} $borderRadius="16px">
                  <div style={{ color: '#1e192b' }}>
                    {' '}
                    <Trans>Connect Wallet</Trans>{' '}
                  </div>
                </ButtonPrimary>
              </TraceEvent>
            ) : chainId && chainId !== connectedChainId ? (
              <ButtonPrimary
                $borderRadius="16px"
                onClick={async () => {
                  try {
                    await switchChain(connector, chainId)
                  } catch (error) {
                    if (didUserReject(error)) {
                      // Ignore error, which keeps the user on the previous chain.
                    } else {
                      // TODO(WEB-3306): This UX could be improved to show an error state.
                      throw error
                    }
                  }
                }}
              >
                Connect to {getChainInfo(connectedChainId)?.label}
              </ButtonPrimary>
            ) : showWrap ? (
              <ButtonPrimary
                $borderRadius="16px"
                disabled={Boolean(wrapInputError)}
                onClick={handleOnWrap}
                fontWeight={535}
                data-testid="wrap-button"
              >
                {wrapInputError ? (
                  <WrapErrorText wrapInputError={wrapInputError} />
                ) : wrapType === WrapType.WRAP ? (
                  <Trans>Wrap</Trans>
                ) : wrapType === WrapType.UNWRAP ? (
                  <Trans>Unwrap</Trans>
                ) : null}
              </ButtonPrimary>
            ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
              <GrayCard style={{ textAlign: 'center' }}>
                <ThemedText.DeprecatedMain mb="4px">
                  <Trans>Insufficient liquidity for this trade.</Trans>
                </ThemedText.DeprecatedMain>
              </GrayCard>
            ) : (
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={SharedEventName.ELEMENT_CLICKED}
                element={InterfaceElementName.SWAP_BUTTON}
              >
                <ButtonError
                  onClick={() => {
                    showPriceImpactWarning ? setShowPriceImpactModal(true) : handleContinueToReview()
                  }}
                  id="swap-button"
                  data-testid="swap-button"
                  disabled={isSwapButtonDisabled}
                  error={!swapInputError && priceImpactSeverity > 2 && allowance.state === AllowanceState.ALLOWED}
                >
                  <Text fontSize={20}>
                    {swapInputError ? (
                      swapInputError
                    ) : routeIsSyncing || routeIsLoading ? (
                      <Trans>Swap</Trans>
                    ) : priceImpactSeverity > 2 ? (
                      <Trans>Swap Anyway</Trans>
                    ) : (
                      <Trans>Swap</Trans>
                    )}
                  </Text>
                </ButtonError>
              </TraceEvent>
            )}
          </ButtonSwapContainer>
          {isLHSwap && <LiquidityHub.PoweredByOrbs style={{ marginBottom: 30 }} />}
        </AutoColumn>
      </SwapWrapper>
      <LiquidityHub.SwapModal
        fromTokenUsd={bestQuote?.fiatValueInput.data}
        toTokenUsd={bestQuote?.fiatValueOutput.data}
        priceImpact={bestQuote?.priceImpact}
        onCurrencySelection={onCurrencySelection}
        onUserInput={onUserInput}
      />
    </SwapContainer>
  )

  return <>{swapElement}</>
}

const BestSwapDetailsDropdownContainer = ({
  wrappedTrade,
  loading,
  isLHSwap,
  allowedSlippage,
  priceImpact,
}: {
  wrappedTrade?: IHasTradeProperties
  loading: boolean
  allowedSlippage: Percent
  isLHSwap: boolean
  priceImpact?: Percent
}) => {
  ;``
  const [showDetails, setShowDetails] = useState(false)

  if (isLHSwap) {
    return (
      <LiquidityHub.SwapDetails
        tradePriceComponent={wrappedTrade && <TradePrice price={wrappedTrade?.executionPrice} />}
        priceImpact={priceImpact}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
      />
    )
  }

  return (
    <SwapDetailsDropdown
      trade={wrappedTrade}
      //TODO: figure out whether we need syncing since the other routes would probably take priority
      syncing={false}
      loading={loading}
      allowedSlippage={allowedSlippage}
      showDetails={showDetails}
      setShowDetails={setShowDetails}
    />
  )
}

interface Props {
  className?: string
  prefilledState?: Partial<SwapState>
  chainId?: ChainId
  onCurrencyChange?: (selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>) => void
  disableTokenInputs?: boolean
}

// eslint-disable-next-line import/no-unused-modules
export const BestSwapInnerContent = (props: Props) => {
  return (
    <LiquidityHub.Provider>
      <BestSwapContent {...props} />
    </LiquidityHub.Provider>
  )
}
